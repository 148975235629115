@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';

.main {
  background-color: white;
}

.article {
  margin-top: spacings.$XL;
}

.weHaveYourBack {
  background: linear-gradient(1deg, #f4f0ff -128.96%, rgba(244, 240, 255, 0) 231.8%);
}

.sideBySideMediaAccordion {
  background-color: colors.$purple100 !important;
}

.article .vrmLookupBlog {
  display: none !important;
  @include mediaqueries.mediumUp {
    display: flex !important;
    margin-top: 64px;
  }
}

.whyChooseFixter {
  display: none !important;
  @include mediaqueries.mediumUp {
    display: flex !important;
    margin-top: 73px;
  }
}

.publisher {
  margin-bottom: 24px;
  margin-top: 24px;
  @include mediaqueries.mediumUp {
    margin-top: 0;
    border-bottom: 1px solid colors.$neutral200;
    padding-bottom: spacings.$S;
  }
}
